#MissionBurrito{

    font-family: proxima-nova,helvetica,arial,sans-serif;
    margin-top: 180px;
    color: #03293c;

    ::selection{
        background: #d9004b;
        color: #ffffff;
    }
    
    .reverseSquare{

        .link-button{

            .buttonCase{
                padding: 10px 35px;
                border: 2px solid #f7b633;
                background-color: #ffffff;
                color: #03293c;
                transform: translate(-50% , 60%);
                font-weight: lighter;
                transition: all 0.3s;
                
                
                &:hover{
                    color: #ffffff;
                    background-color: #f7b633;
                }
            }
        }
            
        .textBurrito{
            width: 55%;
            border: 2px solid #c6e4f7;
            font-size: 19px;
            padding: 40px 50px 50px;
            text-align: center;
            margin-top: 35px;

            @media (max-width:991px) {
                width: 70% !important;
            }

            @media (max-width:767px) {
                width: 90% !important;
            }

            @media (max-width:503px) {
                width: 100% !important;
            }

            @media (max-width:400px) {
                font-size: 17px !important;
            }
        }
        
        &::before{
            
            content: "";
            padding: 10px 10px;
            position: absolute;
            background-color: #ffffff;
            border-top: 1px solid #c6e4f7;
            border-left: 1px solid #c6e4f7;;
            margin-top: 25px;
            transform: rotate(45deg);
            z-index: 1;
        }
    }    
}