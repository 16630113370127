@import '../Styles/components/';
@import '../Styles/conf/normalizer';

*{
    box-sizing: border-box;
}

section{
    width: 100%;
}

