#Homebanner{
    background-color: #F5DB36;
    height: calc(100vh - 102px);

    @media (max-width:991px) {
        height: calc(100vh - 195px);
    }

    @media (max-width:503px) {
        height: 75vh;
    }

    @media (max-width:450px) {
        height: 60vh;
    }


    .Colbanner{
        text-decoration: none;
        color: #03293c;
        font-size: 50px;
        font-weight: 100;
        width: 100%;
        height: 100%;

            .wrapper{
                width: 100%;
                height: 100%;

                .content-root{
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    .container-content{

                        .Bestideas{
                            font-size: 55px;
                            margin-bottom: -90px;

                            @media (max-width:767px) {
                                font-size: 40px;
                                margin-bottom: -70px;
                            }

                            @media (max-width:600px) {
                                font-size: 30px;
                                margin-bottom: -50px;
                            }

                            @media (max-width:503px) {
                                font-size: 22px;
                                margin-bottom: -50px;
                            }

                            @media (max-width:450px) {
                                font-size: 18px;
                                margin-bottom: -40px;
                            }
                            
                        }
                            
                        .Rootwhite{
                            color: white;
                            font-weight: 900;
                            font-size: 250px;

                            @media (max-width:767px) {
                                font-size: 200px;
                            }

                            @media (max-width:600px) {
                                font-size: 170px;
                            }

                            @media (max-width:503px) {
                                font-size: 150px;
                            }

                            @media (max-width:450px) {
                                font-size: 110px;
                            }
                        }
                    }

                }
            }

    
        

    ::selection{
        background: transparent;

    }
    }

    .content-cta{
        position: absolute;
        width: 100%;
        height: 95%;

        @media (max-width:520px) {
            display: none !important;
        }

        .Colchester{
            max-width: fit-content;
            transition: all 0.3s;
    
            &:hover{
                color: #ffffff;
            }
                    
            >.Seework {
                font-size: 25px;
            }
            >.Chester {
                font-size: 25px;
                font-weight: bold;
            }
            ::selection{
                background: #d9004b;
                color: #ffffff;
            }
        }
    }


    .container-jirafa{
        height: 100%;
        width: 100%;
        position: absolute;
        right: 30%;

        @media (max-width:1199px) {
            right: 26% !important;
        }

        @media (max-width:991px) {
            right: 20% !important;
        }

        @media (max-width:767px) {
            right: 12% !important;
        }

        .jirafa{
            height: 60%;
            max-width: fit-content;

            @media (max-width:600px) {
                height: 58%;
            }


            .img-jirafa{
                height: 100%;
                width: 100%;
            }
        }
    }

}