#HappyFry {
    background-color: #ffffff;

    
    .TextHappy{
        font-size: 22px;
        font-weight: 300;
        justify-content: center;
        padding-top: 100px;

        .title_ {
            font-size: 41px ;
            padding-bottom: 50px;

            @media (max-width:1199px) {
                text-align: center !important;
            }
    
            span{       
                text-decoration: underline #d9004b;
                text-decoration-thickness: 1px;
            }
        
    
            .Happy {
                font-weight: bold;
            }
        
            .Makeyour {
                font-weight: 200;
            }
        
            ::selection{
                background: #d9004b;
                color: #ffffff;
            }
        }

        .Big-font{
            @media (max-width:991px) {
                text-align: center;
            }
        }

        .Little-font{
            font-size: 18px;

            @media (max-width:991px) {
                margin-top: 30px;
                text-align: center;
            }
        }

        ::selection{
            background: #d9004b;
            color: #ffffff;
        }
    }
}