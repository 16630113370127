#Proyects{
    padding-bottom: 100px;
    margin-top: 100px;
    background: rgb(254,248,233);
    background: linear-gradient(0deg, rgba(254,248,233,1) 80%, rgba(255,255,255,1) 80%);

    
    @media (max-width:1199px) {
        background: rgb(254,248,233);
        background: linear-gradient(0deg, rgba(254,248,233,1) 87%, rgba(255,255,255,1) 87%);
    }
    
    @media (max-width:991px) {
        background: #fef8e9 !important;
    }


    ::selection{
        background: #d9004b;
        color: #ffffff;
    }
    

    .proyect{
        width: 100%;
        height: fit-content;
        text-decoration: none;
        color: #000;
        transition: all 0.5s;

        .proyectImg{
            height: 300px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .proyect-content{

            h6{
                color: #d9004b;
                font-weight: bold;
            }

            min-height: 50px;
            width: 100%;
            background-color: #ffffff;
            padding: 30px 30px;

            :last-child::after{
                content: " →";
                color: #d9004b;
            }
        }

        &:hover{
            transform: scale(1.04);
            box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.34);
        }
    }
    
}