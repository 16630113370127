#Collage {
    margin-top: 110px;

    ::selection{
        background: #d9004b;
        color: #ffffff;
    }

    .article-url {
        width: fit-content;
        height: fit-content;

    .article-container{
        overflow: hidden;
        width: 100%;
        height: 530px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width:503px) {
            height: 400px !important;
        }
        
            .article-content{
                transition:all 0.5s;
                position: absolute;
                text-decoration: none;
                opacity: 0;
                color: #ffffff;
                font-weight: 100;
                font-size: 18px;

                @media (max-width:503px) {
                    width: 100% !important;
                    height: 100% !important;
                    opacity: 1 !important;
                    right: 0% !important;
                    top: 55% !important;
                    bottom: 0% !important;
                    align-items: flex-end !important;
                }

    
                &.right{
                    height: 530px;
                    width: 300px;
                    right: -100%;
                    background-color: #03293c;
                    padding: 25px 30px 0px;

                    @media (max-width:373px) {
                        top: 45% !important;
                    }
                }
    
                &.top{
                    height: 35%;
                    width: 100%;
                    top: -30%;
                    background-color: #e94d70;
                    padding: 25px;

                    @media (max-width:1199px) {
                        height: 40% !important;
                    }

                    @media (max-width:373px) {
                        top: 50% !important;
                        height: 50% !important;
                    }


                }
    
                &.bottom{
                    height: 33%;
                    width: 100%;
                    bottom: -30%;
                    background-color: #469787;
                    padding: 25px 30px 0px;

                    @media (max-width:1199px) {
                        height: 40% !important;
                    }

                    @media (max-width:373px) {
                        top: 50% !important;
                        height: 50% !important;
                    }
                }
            }
             
            &:hover{
                .article-content {
                    opacity: 1;
                    
                    &.right{
                        right: 0;
                    }
                    &.top{
                        top: 0;
                    }
                    &.bottom{
                        bottom: 0;
                    }
                }
            } 
        }
    }




    
    

    .imgtop {
        margin-top: 24px;
    }

    .lineBack{
        top: 50px;
        
        &::before{
            content: "";
            background-color: #e6eaec;
            text-decoration: none;
            height: 2px;
            width: 97%;
            position: absolute;
            top: 50%;
            z-index: -1;
        }
    }
    
    .buttonshow{
        background-color: #ffffff;
        border: 2px solid #e6eaec;
        text-decoration: none;
        padding: 16px 34px;
        font-family: proxima-nova,helvetica,arial,sans-serif;
        font-weight: 100;
        transition: all 0.3s;

        &:hover{
            color: #f7b633;
            border: 2px solid #f7b633;
        }
        }

}
