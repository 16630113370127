#navbar {
    background-color: #F5DB36;
    position: sticky;
    top: 0px;
    z-index: 30;
    width: 100%;
    

    .logo {
        text-decoration: none;

        @media (max-width:991px) {
            padding-top: 20px;
        }

        @media (max-width:503px) {
            justify-content: end !important;
            padding-top: 10px;
        }


        div{
            color: #03293c;
            font-size: 35px;
            width: fit-content;
            font-weight: bold;
            transition: all 0.3s;
        
            &:hover{
                color: #ffffff;
            }
         
            span{
                font-weight:100;
            }
        }
        ::selection{
            background: transparent;
        }
    }

    .burguer{
        display: none !important;

        @media (max-width:503px) {
            display: flex !important;
            padding-top: 30px;
            justify-content: flex-start !important;
            align-items: flex-end;

        }

        .icon-container{

            border: solid 3px transparentize($color: gray, $amount: 0.5%);
            margin-bottom: 20px;

            .icon{
                height: 30px;
                width: 30px;
                background-color: transparentize($color: transparent, $amount: 0.5%);
            }
        }
    }

    .nav-list {
        height: 102px;

        @media (max-width:991px) {
            justify-content: center !important;
            height: 0%;
            padding-bottom: 20px;
        }

        @media (max-width:503px) {
            padding-bottom: 0px;
        }
       
       .icon-container{
       
            border: solid 3px transparentize($color: gray, $amount: 0.5%);

            &.inside{
                position: absolute !important;
                right: 12% !important;
                top: 4% !important;

                @media (max-width:400px) {
                    right: 6% !important;
                }
                }

                .icon{
                    height: 30px;
                    width: 30px;
                    background-color: transparentize($color: transparent, $amount: 0.5%);
                }
        }

        .logo {
            text-decoration: none;
            position: absolute;
            top: 0%;
            left: 16%;
            display: none !important;

            @media (max-width:503px) {
                display: flex !important;
                top: 2% !important;
            }

            @media (max-width:400px) {
                left: 5% !important;
            }
    
            div{
                color: #ffffff;
                font-size: 35px;
                width: fit-content;
                font-weight: bold;
                transition: all 0.3s;
    
                &:hover{
                    color: #F5DB36;
                }
             
                span{
                    font-weight: 100;
                }
            }
            
            ::selection{
                background: transparent;
            }
        }

        
        .hoverli {


            &.column{
                position: absolute;
                display: flex !important;
                top: 0%;
                background-color: #d9004b;
                flex-direction: column;
                height: 100vh;
                width: 100vw;
                text-align: center;
                opacity: 1;
                margin-top: 0% !important;
                

                li{
                    border: 0px;
                    margin-right: 0% !important;
                    margin-top: 5%;

                    &:hover, &.selected{
                        border: 0px !important;
                    }

                    a{
                        font-size: 30px;
                        font-weight: bold;
                        color: #ffffff;
                        transition: all 0.3s;

                        &:hover{
                            color:#F5DB36;
                        }
                    }
                }
            }

            
            font-family: proxima-nova,helvetica,arial,sans-serif;
            margin-bottom: 0%;
            
            @media (max-width:1199px) {
                padding-left: 0% !important;
            }
            
            @media (max-width:991px) {
                justify-content: center !important;
                margin-top: 20px !important;
            }

            @media (max-width:503px) {
                transition: 0.3s;
                position: absolute;
                top: -500px;
            }


            
            li {
                padding: 9px 17px;
                list-style: none;
                border: 2px solid transparent;
                transition: 0.3s;

                @media (max-width:503px) {
                    padding: 0% !important;
                }
                
                &:hover, &.selected {
                    border: 2px solid #ffffff;
                }
                
                a{
                    text-decoration: none;
                    color: #03293c;
                    font-size: 16px;
                    font-weight:bold;
                }
                
            }
        }
            
            ::selection{
                background: #d9004b;
                color: #ffffff;
            }
    }

}
