#Footer{
    background-color: #d9004b;
    color: #ffffff;
    padding-top: 60px;
    padding-bottom: 50px;

    ::selection{
        color: white;
    }

    a{
        text-decoration: none;
        color: #ffffff;
        font-weight: 100;
        transition: all 0.3s;

        :hover{
            color: #f7b633;
        }
    }

    .footer-container{
        justify-content: space-between;

        @media (max-width:1199px) {
            flex-direction: column;
            align-items: center;
        }

        .root-studio{
            &::before{
                content: "© ";
                font-weight: 100;
            }
        }

        .footer-content{
            @media (max-width:499px) {
                flex-direction: column;
                align-items: center;
            }

            .content{
                @media (max-width:499px) {
                margin: 0% !important;
                text-align: center;
            }
        }

            .content2{
                @media (max-width:499px) {
                    margin-left: 0% !important;
                    text-align: center !important;
                    margin-top: 30px !important;
                }
            }
        }

        .social-container{
            @media (max-width:1199px) {
                margin-top: 40px;
            }

            @media (max-width:499px) {
                flex-direction: column;
                align-items: center;
            }

            .social-media{
                @media (max-width:499px) {
                    margin-left: 0% !important;
                    margin-top: 40px !important;
                    text-align: center !important;

                }
            }
        }
    }
}
